import { CriticalDateType } from '@/bundles/CriticalDates/types';

const typeDictionary = {
  [CriticalDateType.effective_date_start]: 'Option Effective Date Start',
  [CriticalDateType.effective_date_end]: 'Option Effective Date End',
  [CriticalDateType.no_later_than_date]: 'No Later Than Notification',
  [CriticalDateType.no_earlier_than_date]: 'No Earlier Than Notification',
};

export const mapCriticalDateTypeToTitle = (type: CriticalDateType): string => {
  return typeDictionary[type] || 'No Earlier Than Notification';
}
