
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue'
import { formatCurrency, formatNumber } from '@/utils/filters';
import { Nullable } from '@/utils/types';

import Label from '@/bundles/Common/components/Label.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';

interface IProps {
  totalDeals: Nullable<number>;
  showBrokerGross: boolean;
  reportType: string;
  totalConsideration: Nullable<number>;
  totalBrokerGross: Nullable<number>;
  totalBrokerNet: Nullable<number>;
  totalBrokerNet2: Nullable<string>;
  totalBuildingSize: Nullable<number>;
  totalLandSize: Nullable<number>;
}

export default Vue.extend({
  name: 'AgentRankingSummaryMobileCard',

  functional: true,

  props: {
    totalDeals: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
    showBrokerGross: Boolean,
    reportType: {
      type: String,
      default: 'paid'
    },
    totalConsideration: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
    totalBrokerGross: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
    totalBrokerNet: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
    totalBrokerNet2: {
      type: String as PropType<Nullable<string>>,
      default: null
    },
    totalBuildingSize: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
    totalLandSize: {
      type: Number as PropType<Nullable<number>>,
      default: null
    },
  },

  render (h: CreateElement, { props, data, listeners }: RenderContext<IProps>): VNode | VNode[] {
    return h('v-card', {
      props: { outlined: true },
      attrs: { ...data.attrs },
      class: `agent-ranking-summary-mobile-card ${data.class || ''} ${data.staticClass || ''}`.trim(),
    }, [
      h('div', { class: 'd-flex align-center' }, [
        h('span', { class: 'pa-3 agent-ranking-summary-mobile-card__name font-weight-bold' }, 'Agent Ranking Details'),
        h('v-spacer'),
        h('v-btn', {
          props: { icon: true, small: true },
          class: 'mr-2',
          on: {
            click: () => {
              (listeners['click:close'] as Function)()
            }
          }
        }, [
          h('v-icon', { props: { small: true } }, 'mdi-close')
        ]),
      ]),
      h('v-divider'),
      h('div', { class: 'd-flex gap-2 flex-column pa-3' }, [
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__deals-label' }, '# of Deals:'),
            h('div', { class: 'agent-ranking-summary-mobile-card__deals' }, [
              h(Label, { props: { value: formatNumber(props.totalDeals) } })
            ])
          ]),
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__consideration-label' }, 'Total Consideration:'),
            h('div', { class: 'agent-ranking-summary-mobile-card__consideration' }, [
              h(Label, { props: { value: formatCurrency(props.totalConsideration) } })
            ])
          ])
        ]),
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__building-size-label' }, 'Total Bldg Size:'),
            h('div', { class: 'agent-ranking-summary-mobile-card__building-size' }, [
              h(BuildingSizeLabel, { props: { value: props.totalBuildingSize } })
            ])
          ]),
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__land-size-label' }, 'Total Land Size:'),
            h('div', { class: 'agent-ranking-summary-mobile-card__land-size' }, [
              h(LandSizeLabel, { props: { value: props.totalLandSize } })
            ])
          ])
        ]),
        props.showBrokerGross ? h('div', { class: 'd-flex flex-column gap-3' }, [
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__agent-gross-label' }, 'Agent Gross:'),
            h('div', { class: 'agent-ranking-summary-mobile-card__agent-gross' }, [
              h(Label, { props: { value: formatCurrency(props.totalBrokerGross) } })
            ])
          ]),
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__agent-net-label' }, 'Agent Net:'),
            h('div', { class: 'agent-ranking-summary-mobile-card_agent' }, [
              h(Label, { props: { value: props.reportType === 'paid' ? formatCurrency(props.totalBrokerNet) : null } })
            ])
          ]),
          h('div', { class: 'agent-ranking-summary-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-summary-mobile-card__info-label agent-ranking-summary-mobile-card__vacancy-status-label' }, 'Broker Net:'),
            h('div', { class: 'agent-ranking-summary-mobile-card__vacancy-status' }, [
              h(Label, { props: { value: props.totalBrokerNet2 } })
            ])
          ])
        ]) : null,
      ]),
    ])
  }
})
