
import Vue, { CreateElement, RenderContext, VNode } from 'vue'

export default Vue.extend({
  name: 'AppTooltip',

  functional: true,

  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
    bottom: {
      type: Boolean,
      default: true,
    }
  },

  render (h: CreateElement, { props, scopedSlots, data }: RenderContext<{ text: string, icon: string; bottom: boolean; }>): VNode {
    return h('v-tooltip', {
      props: {
        bottom: props.bottom,
      },
      class: `app-tooltip ${data.class || ''} ${data.staticClass || ''}`.trim(),
      attrs: { ...data.attrs },
      scopedSlots: {
        activator: ({ on, attrs }) => {
          if (scopedSlots.default) {
            return scopedSlots.default({ on, attrs });
          }

          return h('v-icon', {
            ...attrs,
            class: 'cursor-pointer',
            on: { ...on },
          }, props.icon);
        }
      }
    }, [
      h('span', { class: 'app-tooltip__text' }, props.text),
    ]);
  }
})
