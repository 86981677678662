
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue'
import CriticalDateListResponse from '@/bundles/CriticalDates/models/CriticalDateListResponse';
import { mapCriticalDateTypeToTitle } from '@/bundles/CriticalDates/helpers';

import Label from '@/bundles/Common/components/Label.vue';
import { formatDate } from '@/utils/filters';
import { getAccountRouteHandler } from '@/bundles/App/helpers';

export default Vue.extend({
  name: 'CriticalDateMobileCard',

  functional: true,

  props: {
    item: {
      type: Object as PropType<CriticalDateListResponse>,
      required: true,
    },
    activeAccountId: {
      type: Number,
      required: true
    }
  },

  render (h: CreateElement, { props, data }: RenderContext<{ item: CriticalDateListResponse; activeAccountId: number }>): VNode | VNode[] {
    return h('v-card', {
      props: { outlined: true },
      class: `critical-date-mobile-card ${data.class || ''} ${data.staticClass || ''}`.trim(),
      attrs: { ...data.attrs },
    }, [
      h('div', { class: 'd-flex align-center' }, [
        h('span', { class: 'critical-date-mobile-card__title' }, mapCriticalDateTypeToTitle(props.item.type)),
        h('v-spacer'),
        h('div', { class: 'critical-date-mobile-card__date-chip' }, [
          h('div', 'Date'),
          h('b', { class: 'critical-date-mobile-card__date-chip-date' }, formatDate(props.item.date))
        ])
      ]),
      h('div', { class: 'd-flex flex-column gap-1 pa-3' }, [
        h('div', { class: 'critical-date-mobile-card__info critical-date-mobile-card__type' }, [
          h('span', { class: 'critical-date-mobile-card__info-label' }, 'Type: '),
          h('span', props.item.lease_date.type_name)
        ]),
        h('div', { class: 'critical-date-mobile-card__info critical-date-mobile-card__occupier' }, [
          h('span', { class: 'critical-date-mobile-card__info-label' }, 'Occupier: '),
          props.item.lease_date.occupier ? h('router-link', {
            class: 'app-link critical-date-mobile-card__occupier-name',
            props: {
              to: getAccountRouteHandler({
                name: 'occupier',
                params: { id: props.item.lease_date.occupier._key },
                activeAccountId: props.activeAccountId,
              })
            },
            attrs: { target: '_blank' }
          }, props.item.lease_date.occupier.name) : h(Label)
        ]),
        h('div', { class: 'critical-date-mobile-card__info critical-date-mobile-card__description' }, [
          h('span', { class: 'critical-date-mobile-card__info-label mr-1' }, 'Description: '),
          props.item.lease_date.description ? h('span', { domProps: { innerHTML: props.item.lease_date.description } }) : h(Label)
        ])
      ])
    ])
  }
})
