
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue'
import { IAvailability } from '@/bundles/Availabilities/types';
import { getFormattedAddress } from '@/bundles/App/helpers/address/getFormattedAddress';
import { formatNumber } from '@/utils/filters';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { getAccountRouteHandler } from '@/bundles/App/helpers';

import AvailabilityChipsWrapper from '@/bundles/Availabilities/components/chips/AvailabilityChipsWrapper.vue';
import Label from '@/bundles/Common/components/Label.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';

export default Vue.extend({
  name: 'AvailabilityMobileCard',

  functional: true,

  props: {
    item: {
      type: Object as PropType<IAvailability>,
      required: true
    },
    activeAccountId: {
      type: Number,
      required: true
    }
  },

  render (h: CreateElement, { props, data }: RenderContext<{ item: IAvailability; activeAccountId: number }>): VNode | VNode[] {
    return h('v-card', {
      props: { outlined: true },
      class: `availability-mobile-card ${data.class || ''} ${data.staticClass || ''}`.trim(),
      attrs: { ...data.attrs },
    }, [
      h('div', { class: 'd-flex gap-2' }, [
        h('v-img', {
          props: {
            src: props.item.photo_links?.medium_url || props.item?.photo?.url || (parent as any).$placeholderPropertyImg,
            aspectRatio: 16 / 9,
            contain: true,
            position: 'center top',
            minWidth: '160px',
            maxWidth: '320px'
          },
          class: 'availability-mobile-card__img'
        }, [
          props.item.status_label ? h('div', { class: 'transaction-type-label' }, props.item.status_label) : null
        ]),
        h('div', { class: 'availability-mobile-card__address-info px-3 pt-3' }, [
          h('div', { class: 'record-id' }, `Availability ID# ${props.item._key}`),
          h('router-link', {
            props: {
              to: getAccountRouteHandler({
                name: 'availability',
                params: { id: props.item._key },
                activeAccountId: props.activeAccountId,
              })
            },
            class: 'font-weight-bold app-link availability-mobile-card__property-name'
          }, props.item.property.name),
          h('div', { class: 'white-space--pre-line availability-mobile-card__address' }, [
            getFormattedAddress({ ...props.item.address, street2: props.item.suite }, { includeStreet2: true })
          ])
        ])
      ]),
      h('div', { class: 'pa-3 pt-2 d-flex flex-column gap-2' }, [
        h(AvailabilityChipsWrapper, {
          props: {
            agencyType: props.item.agency_type,
            inHouse: props.item.in_house,
            status: props.item.status,
            type: props.item.type,
            mixedUse: props.item.mixed_use,
            vacancyType: props.item.vacancy_type,
            isPortfolio: props.item.is_portfolio,
            buildingClass: props.item.property.building.class,
            contractPending: props.item.contract_pending,
            isPublishedToWeb: props.item.published_to_web.value,
            isOneLine: true,
            allowWrap: true
          }
        }),
        h('div', { class: 'd-flex gap-2 flex-column' }, [
          h('div', { class: 'd-flex gap-3' }, [
            h('div', { class: 'availability-mobile-card__info' }, [
              h('div', { class: 'availability-mobile-card__info-label availability-mobile-card__available-space-label' }, 'Available Space:'),
              h('div', { class: 'availability-mobile-card__available-space' }, [
                h(BuildingSizeLabel, { props: { value: props.item.available_space } })
              ])
            ]),
            h('div', { class: 'availability-mobile-card__info' }, [
              h('div', { class: 'availability-mobile-card__info-label availability-mobile-card__divisible-space-label' }, 'Divisible Space:'),
              h('div', { class: 'availability-mobile-card__divisible-space' }, [
                h(BuildingSizeLabel, { props: { value: props.item.divisible_space } })
              ])
            ])
          ]),
          h('div', { class: 'd-flex gap-3' }, [
            h('div', { class: 'availability-mobile-card__info' }, [
              h('div', { class: 'availability-mobile-card__info-label availability-mobile-card__building-size-label' }, 'Building Size:'),
              h('div', { class: 'availability-mobile-card__building-size' }, [
                h(BuildingSizeLabel, { props: { value: props.item.building_size } })
              ])
            ]),
            h('div', { class: 'availability-mobile-card__info' }, [
              h('div', { class: 'availability-mobile-card__info-label availability-mobile-card__year-built-label' }, 'Year Built:'),
              h('div', { class: 'availability-mobile-card__year-built' }, [
                h(Label, { props: { value: isValuePresent(props.item.property.building.year_built) ? formatNumber(props.item.property.building.year_built) : null } })
              ])
            ])
          ]),
          h('div', { class: 'd-flex gap-3' }, [
            h('div', { class: 'availability-mobile-card__info' }, [
              h('div', { class: 'availability-mobile-card__info-label availability-mobile-card__construction-status-label' }, 'Construction Status:'),
              h('div', { class: 'availability-mobile-card__construction-status' }, [
                h(Label, { props: { value: props.item.property.building.construction_status } })
              ])
            ]),
            h('div', { class: 'availability-mobile-card__info' }, [
              h('div', { class: 'availability-mobile-card__info-label availability-mobile-card__vacancy-status-label' }, 'Vacancy Status:'),
              h('div', { class: 'availability-mobile-card__vacancy-status text-capitalize' }, [
                h(Label, { props: { value: props.item.vacancy_status } })
              ])
            ])
          ])
        ])
      ])
    ])
  }
})
