import { ITextValue } from '@/types/common';
import { Nullable } from '@/utils/types';
import { IComparable } from '@/bundles/Comparables/types';

export * from './comparablesHeadersData';
export * from './mapHelpers';

export enum RentTypeEnum {
  per_month = 'Months',
  per_year = 'Years'
}

export const RentTypeLabels = {
  [RentTypeEnum.per_month]: 'Per Month',
  [RentTypeEnum.per_year]: 'Per Year'
}

export const getRentTypeItems = (): ITextValue<string>[] => {
  return Object.keys(RentTypeLabels).map(key => {
    return {
      text: RentTypeLabels[key],
      value: key
    }
  })
};

export const getComparableLeaseTermLength = (comparable: IComparable): Nullable<string> => {
  if (!comparable?.transaction?.lease_term_length) {
    return null;
  }
  const leaseTerm = `${comparable.transaction.lease_term_length} ${comparable.transaction.lease_term}`;

  if (comparable.rent_schedule?.periods?.length) {
    const calculatedTerm = {
      days: 0,
      months: 0
    };

    comparable.rent_schedule.periods.reduce((acc, item) => {
      acc[item.period_type] += item.periods;

      return acc;
    }, calculatedTerm);

    return `${calculatedTerm.months ? `${calculatedTerm.months} Months` : ''} ${calculatedTerm.days ? `${calculatedTerm.days} Days` : ''}`.trim();
  }

  return leaseTerm;
};
