import {
  getAvailabilitiesTableOptions,
  getCompaniesTableOptions,
  getComparablesTableOptions,
  getContactsTableOptions,
  getDealsTableOptions,
  getOccupiersTableOptions,
  getPipelinesTableOptions,
  getProjectsTableOptions,
  getPropertiesTableOptions,
  getRequirementsTableOptions,
  getSavedSetsTableOptions,
  getSignListTableOptions,
  getTableOptions
} from '@/bundles/BaseTable/helpers/consts';

export const keys = ['occupier', 'company', 'availability', 'property',
  'comparable', 'deal', 'contact', 'requirement', 'project', 'pipeline', 'savedSet',
  'member', 'team', 'request', 'eblast', 'pressrelease', 'marketing', 'import', 'criticalDatesDashboardWidget', 'marketingTemplate',
  'marketingCampaign', 'customRow', 'signList'
];

export const tableOptionsDictionary = {
  company: () => getCompaniesTableOptions(),
  contact: () => getContactsTableOptions(),
  occupier: () => getOccupiersTableOptions(),
  requirement: () => getRequirementsTableOptions(),
  property: () => getPropertiesTableOptions(),
  availability: () => getAvailabilitiesTableOptions(),
  comparable: () => getComparablesTableOptions(),
  project: () => getProjectsTableOptions(),
  savedSet: () => getSavedSetsTableOptions(),
  deal: () => getDealsTableOptions(),
  pipeline: () => getPipelinesTableOptions(),
  signList: () => getSignListTableOptions(),
};

export const getInitialTableOptionsByType = (key) => {
  return key in tableOptionsDictionary ? tableOptionsDictionary[key]() : getTableOptions();
};

export function tableOptionsStateBuilder () {
  const state = {};

  keys.forEach(key => {
    const tableOptions = getInitialTableOptionsByType(key);

    state[key] = {
      tableOptions,
      selected: []
    };
  });

  return state;
}
