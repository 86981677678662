import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { Nullable } from '@/utils/types';

const BugTrackerMixin = Vue.extend({
  props: {
    voteLoadingKeys: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },

  computed: {
    ...mapState({
      userId: (state: any) => state.userId,
    }),
  },

  methods: {
    isVoted (votes: Nullable<string[]>): boolean {
      if (!votes) return false;

      return votes.some(userId => +userId === +this.userId);
    },
    isVoteLoading (key): boolean {
      return this.voteLoadingKeys.some(voteKey => +voteKey === +key);
    }
  }
})

export { BugTrackerMixin };
