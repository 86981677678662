var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"dashboard-table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.value.itemsPerPage,"item-key":"number","mobile-breakpoint":0,"expanded":_vm.expanded,"sort-by":_vm.value.sortBy,"sort-desc":_vm.value.sortDesc,"page":_vm.value.page,"loading":_vm.filterLoading,"hide-default-header":_vm.$vuetify.breakpoint.mdAndDown,"footer-props":{ itemsPerPageText: 'Per page:' }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){return _vm.updateModel({ page: $event })},"update:sort-by":function($event){return _vm.updateModel({ sortBy: $event })},"update:sort-desc":function($event){return _vm.updateModel({ sortDesc: $event })},"update:items-per-page":function($event){return _vm.updateModel({ itemsPerPage: $event })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
var index = ref.index;
return [(_vm.$vuetify.breakpoint.mdAndDown)?_c('OpenBalancesMobileCard',{class:index === 0 ? 'mt-0' : 'mt-2',attrs:{"item":item,"active-account-id":_vm.activeAccountId}}):_c('tr',{staticClass:"sc-broker-ranking-broker sc-open-balances-row",class:("" + (isExpanded ? 'expanded' : '')),on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',{staticClass:"sc-data-list-select",staticStyle:{"width":"80px"}},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item',{staticClass:"ml-0 pl-0 pr-0"},[_c('CommonChip',{staticClass:"sc-nbr"},[_vm._v(_vm._s(item.number))])],1)],1)],1),_c('td',{staticClass:"sc-data-list-subject"},[_c('router-link',{staticClass:"link-to-project",staticStyle:{"display":"inline"},attrs:{"to":_vm.getAccountRoute({ name: 'deal', params: { id: item._key }})}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('br'),(item.status === 'pending')?_c('v-chip',{staticClass:"info overline mt-2",attrs:{"small":"","dark":"","to":_vm.getAccountRoute({ name: 'deal', params: { id: item._key }})}},[_vm._v(" Pending invoice approval ")]):_vm._e()],1),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.transactionDate))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.expected))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.payments))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.remaining))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.inHouseExpected))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.brokerGross))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.brokerGrossRemaining))+" ")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',{staticClass:"broker-ranking-detail"},[_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('OpenBalancesInvoiceTable',{attrs:{"items-entry":item.invoices}})],1)],1)])]}},{key:"body.append",fn:function(){return [(!_vm.$vuetify.breakpoint.mdAndDown)?_c('tr',{staticClass:"grey lighten-2 tfoot"},[_c('td',[_c('v-chip',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.totals.count))])],1),_c('td'),_c('td'),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.expected))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.paid)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.remaining)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.inHouseExpected))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.brokerGross)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.brokerGrossRemaining)))])]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }