
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { ITableOptions } from '@/bundles/BaseTable/interfaces';

interface IProps {
  totalRowsEntry: number;
  items: any[];
  tableOptions: ITableOptions;
  filterLoading: boolean;
}

export default Vue.extend({
  name: 'DashboardMobileTable',

  functional: true,

  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true
    },
    tableOptions: {
      type: Object as PropType<ITableOptions>,
      required: true
    },
    filterLoading: Boolean,
    totalRowsEntry: {
      type: Number,
      required: true
    },
  },

  render (h: CreateElement, { data, listeners, props, scopedSlots }: RenderContext<IProps>): VNode | VNode[] {
    return h('v-data-table', {
      ...data,
      attrs: { ...data.attrs },
      class: `dashboard-table  ${data.class || ''} ${data.staticClass || ''}`.trim(),
      props: {
        items: props.items,
        itemsPerPage: props.tableOptions.itemsPerPage,
        mobileBreakpoint: 0,
        page: props.tableOptions.page,
        loading: props.filterLoading,
        hideDefaultHeader: true,
        footerProps: { itemsPerPageText: 'Per page:' },
        serverItemsLength: props.totalRowsEntry,
      },
      on: {
        ...listeners,
        'update:page': (page: number) => (listeners['update:table-options'] as Function)({ ...props.tableOptions, page }),
        'update:items-per-page': (itemsPerPage: number) => (listeners['update:table-options'] as Function)({ ...props.tableOptions, itemsPerPage }),
      },
      scopedSlots: {
        item: ({ item, index }: { item: any, index: number }) => scopedSlots.item ? scopedSlots.item({ item, index }) : null,
      }
    });
  }
})
