import { IAddress } from '@/utils/interfaces';

interface IGetFormattedAddressOptions {
  includeStreet2?: boolean;
}

export const getFormattedAddress = (address: Partial<IAddress>, options: IGetFormattedAddressOptions = {}): string => {
  let formattedAddress = `${getFormattedStreetAddress(address, options)}\n`;
  formattedAddress += ` ${getFormattedCityStatePostalCountry(address)}`;
  return formattedAddress.trim();
}

export const getFormattedStreetAddress = (address: Partial<IAddress>, options: IGetFormattedAddressOptions = {}): string => {
  let formattedAddress = '';

  const streetAddress = address.street?.trim();
  if (streetAddress) {
    formattedAddress += `${streetAddress}`;
  }

  if (options.includeStreet2) {
    const streetAddress2 = address.street2?.trim();
    if (streetAddress2) {
      formattedAddress += formattedAddress ? `, ${streetAddress2}` : `${streetAddress2}`;
    }
  }

  return formattedAddress.trim();
}

export const getFormattedCityStatePostalCountry = (address: Partial<IAddress>): string => {
  let formattedAddress = '';

  const city = address.city?.trim();
  if (city) {
    formattedAddress += `${city}`;
  }

  const state = address.state?.trim();
  if (state) {
    formattedAddress += formattedAddress ? `, ${state}` : `${state}`;
  }

  const postal = (typeof address.postal) === 'string' ? address.postal?.trim() : address.postal;
  if (postal) {
    formattedAddress += formattedAddress ? ` ${postal}` : `${postal}`;
  }

  const country = address.country?.trim();
  if (country && country !== 'US') {
    formattedAddress += formattedAddress ? `, ${country}` : `${country}`;
  }

  return formattedAddress.trim();
}
