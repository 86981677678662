import { IItemsPerPageOptionsItem, ITableOptions } from '../interfaces';

const defaultItemsPerPageOptions = [
  { title: '25', value: 25 },
  { title: '50', value: 50 },
  { title: '100', value: 100 },
  { title: '250', value: 250 },
  { title: '500', value: 500 },
  { title: '1000', value: 1000 },
  { title: '1500', value: 1500 },
];

export const mergeTableItemsPerPageOptions = [
  { title: '5', value: 5 },
  { title: '10', value: 10 },
  { title: '20', value: 20 },
  { title: '50', value: 50 },
  { title: '100', value: 100 },
];

export const getTableOptions = ({
  itemsPerPage = 50,
  itemsPerPageOptions = defaultItemsPerPageOptions,
  sortBy = [],
  sortDesc = [],
  sortText = '',
}: {
  itemsPerPage?: number;
  itemsPerPageOptions?: IItemsPerPageOptionsItem[];
  sortBy?: string[];
  sortDesc?: boolean[];
  sortText?: string;
} = {}): ITableOptions => ({
  groupBy: [],
  itemsPerPage,
  multiSort: false,
  mustSort: false,
  page: 1,
  sortBy,
  sortDesc,
  itemsPerPageOptions,
  sortText,
});

export const getCompaniesTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getContactsTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getOccupiersTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getRequirementsTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getPropertiesTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getAvailabilitiesTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['created.date'],
    sortDesc: [true],
    sortText: 'Created Date',
  }),
});

export const getComparablesTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['created.date'],
    sortDesc: [true],
    sortText: 'Created Date',
  }),
});

export const getProjectsTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getSavedSetsTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['updated.date'],
    sortDesc: [true],
    sortText: 'Updated Date',
  }),
});

export const getDealsTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['created.date'],
    sortDesc: [true],
    sortText: 'Created Date',
  }),
});

export const getPipelinesTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['size'],
    sortDesc: [true],
    sortText: 'Size',
  }),
});

export const getSignListTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['created.date'],
    sortDesc: [true],
    sortText: 'Created Date',
  }),
});

export const getMarketingCampaignListTableOptions = (): ITableOptions => ({
  ...getTableOptions({
    sortBy: ['created.date'],
    sortDesc: [true],
    sortText: 'Created Date',
  }),
});
