export enum FilterAllFilterOnlyEnum {
  only = 'only',
  all = 'all',
  filter = 'filter',
}

export const FilterAllFilterOnlyLabels = {
  [FilterAllFilterOnlyEnum.only]: `Only`,
  [FilterAllFilterOnlyEnum.all]: 'All',
  [FilterAllFilterOnlyEnum.filter]: `Exclude`,
}

export const FilterAllFilterOnlyItems = Object.entries(FilterAllFilterOnlyLabels).map(([value, text]) => {
  return { value, text };
});
