export type IAvailability = any; // TODO sychdo: create proper availability interface

export enum AvailabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Leased = 'Leased',
  Pending = 'Pending',
  Sold = 'Sold',
  Withdrawn = 'Withdrawn'
}

export enum RateEnteredAsEnum {
  per_sf = 'per_sf',
  per_month = 'per_month'
}
