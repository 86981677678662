export default {
  state: {
    availSize: '',
    availPhone: '',
    availPhoto: '',
    trueOwner: [],
    isUploadingLogo: true,
    curAvailability: {},
    curOrderStatus: [],
    isAvailabilityUpdated: false,
    isOrdering: false,
    isLogoMakChanging: false,
  },
  mutations: {
    UPDATE_AVAI_SIZE (state, newValue) {
      state.availSize = newValue
    },
    UPDATE_AVAI_PHONE (state, newValue) {
      state.availPhone = newValue
    },
    UPDATE_AVAI_PHOTO (state, newValue) {
      state.availPhoto = newValue
    },
    UPDATE_TRUE_OWNER (state, newValue) {
      state.trueOwner = newValue
    },
    UPDATE_ISUPLOADING (state, newValue) {
      state.isUploadingLogo = newValue
    },
    LOGO_MAKE_CHANGING (state, newVal) {
      state.isLogoMakChanging = newVal
    },
  },
  actions: {
    updateAvailSize ({ state, commit }, value) {
      commit('UPDATE_AVAI_SIZE', value)
    },
    updateIsUploading ({ state, commit }, value) {
      commit('UPDATE_ISUPLOADING', value)
    },
    updateAvailPhone ({ state, commit }, value) {
      commit('UPDATE_AVAI_PHONE', value)
    },
    updateAvailPhoto ({ state, commit }, value) {
      commit('UPDATE_AVAI_PHOTO', value)
    },
    updateTrueOwner ({ state, commit }, value) {
      commit('UPDATE_TRUE_OWNER', value)
    },
    isLogoUploadingChanging ({ state, commit }, value) {
      commit('LOGO_MAKE_CHANGING', value)
    },
  },
}
