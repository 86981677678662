import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { Nullable } from '@/utils/types';
import { formatCurrency } from '@/utils/filters';

// lunt1ks todo: temp solution
export const getBrokerNet = ({ brokerGross, brokerNet, reportType, isBrokerNetAllowed }: {
  brokerGross: Nullable<string | number>;
  brokerNet: Nullable<string | number>;
  reportType: string;
  isBrokerNetAllowed: boolean;
}): Nullable<string> => {
  if (reportType === 'gross' || !isValuePresent(brokerGross)) {
    return null;
  }

  if (isBrokerNetAllowed) {
    const value = Number(brokerGross) - Number(brokerNet);
    return formatCurrency(value);
  }

  return null;
}
