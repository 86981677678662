import store from '@/store/store';
const beforeEnterHandler = (to, from, next) => {
  const hasBeePluginSubscription = store.getters['Subscriptions/hasBeePluginSubscription'];

  if (hasBeePluginSubscription) {
    next()
  } else {
    next({ name: 'no-subscription', params: { accountId: (store.state.user as any)?.active_account?.id } })
  }
};

enum DesignManagerSourceSourceEnum {
  'marketing-templates' = 'marketingTemplates',
  'custom-rows' = 'customRows',
  // this hack is needed because AppFiltersPanel requires source to get filters.
  // there is no need to extend filters state with projects and settings filter since we don't have them implemented yet
  'marketing-campaigns' = 'marketingTemplates',
  'marketing-campaign' = 'marketingTemplate',
  'design-manager-settings' = 'marketingTemplates'
}

export default [
  {
    path: 'no-subscription',
    name: 'no-subscription',
    meta: {
      title: 'No Subscription',
    },
    component: () => import('@/bundles/Subscriptions/views/NoSubscription.vue'),
  },
  {
    path: 'marketing/campaigns',
    name: 'marketing-campaigns',
    props: true,
    meta: {
      title: 'Campaigns',
    },
    component: () => import('@/bundles/MarketingTemplate/views/MarketingCampaignList.vue'),
    beforeEnter: beforeEnterHandler,
  },
  {
    path: 'marketing/campaigns/:id',
    name: 'marketing-campaign',
    props: true,
    component: () => import('@/bundles/MarketingTemplate/views/MarketingCampaignRecord.vue'),
    beforeEnter: beforeEnterHandler,
  },
  {
    path: 'design-manager',
    name: 'design-manager',
    meta: {
      title: 'Design Manager',
    },
    props: route => ({
      source: DesignManagerSourceSourceEnum[route.name],
    }),
    redirect: { name: 'design-manager-library' },
    component: () => import('@/bundles/MarketingTemplate/views/DesignManager.vue'),
    children: [
      {
        path: 'library',
        name: 'design-manager-library',
        props: true,
        meta: {
          title: 'Library'
        },
        redirect: { name: 'marketing-templates' },
        component: () => import('@/bundles/MarketingTemplate/views/Library.vue'),
        children: [
          {
            path: 'marketing-templates',
            name: 'marketing-templates',
            meta: {
              title: 'Marketing Templates',
            },
            component: () => import('@/bundles/MarketingTemplate/views/MarketingTemplateList.vue'),
            beforeEnter: beforeEnterHandler,
          },
          {
            path: 'custom-rows',
            name: 'custom-rows',
            props: true,
            meta: {
              title: 'Custom Rows',
            },
            component: () => import('@/bundles/MarketingTemplate/views/CustomRowList.vue'),
            beforeEnter: beforeEnterHandler,
          },
        ]
      },
      {
        path: 'settings',
        name: 'design-manager-settings',
        props: true,
        meta: {
          title: 'Settings',
        },
        component: () => import('@/bundles/MarketingTemplate/views/Settings.vue'),
        beforeEnter: beforeEnterHandler,
      },
    ]
  },
  {
    path: 'marketing-template/:id',
    name: 'marketing-template',
    props: true,
    component: () => import('@/bundles/MarketingTemplate/views/MarketingTemplate.vue'),
    beforeEnter: beforeEnterHandler,
  },
  {
    path: 'custom-rows/:id',
    name: 'custom-row',
    props: true,
    component: () => import('@/bundles/MarketingTemplate/views/CustomRow.vue'),
    beforeEnter: beforeEnterHandler,
  },
];
