
























































































import { mapState } from 'vuex'
import mixins from 'vue-typed-mixins';
import { DashboardLayoutMixin } from '@/bundles/Settings/mixins/DashboardLayoutMixin';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { IUserLayoutWidget } from '@/bundles/Settings/interfaces/IUserLayoutWidget';

import AppBar from '@/components/AppBar.vue';
import BrokerRankingCard from '@/components/reports/BrokerRankingCard.vue';
import BrokerProductionCard from '@/components/reports/BrokerProductionCard.vue';
import AppTopPanel from '@/components/common-components/AppTopPanel.vue';
import Footer from '@/components/Footer.vue';
import Tasks from '@/bundles/Tasks/components/Tasks.vue';
import CriticalDatesDashboardWidget from '@/bundles/CriticalDates/components/CriticalDatesDashboardWidget.vue';
import DashboardAvailabilitiesCard from '@/bundles/Availabilities/components/cards/DashboardAvailabilitiesCard.vue';
import DashboardComparablesCard from '@/bundles/Comparables/components/cards/DashboardComparablesCard.vue';
import DashboardOpenInvoicesCard from '@/bundles/Dashboard/components/DashboardOpenInvoicesCard.vue';

export default mixins(DashboardLayoutMixin).extend({
  name: 'Dashboard',

  components: {
    DashboardComparablesCard,
    DashboardAvailabilitiesCard,
    AppTopPanel,
    Footer,
    Tasks,
    AppBar,
    BrokerRankingCard,
    BrokerProductionCard,
    CriticalDatesDashboardWidget,
    DashboardOpenInvoicesCard
  },

  computed: {
    ...mapState({
      navState: (state: any) => state.nav,
      activeAccountId: (state: any) => state.activeAccount.id,
      showBrokerNet: (state: any) => !!state.accountSettings.accounting?.show_broker_net,
    }),
    userWidgetsEnum (): typeof UserWidgetsEnum {
      return UserWidgetsEnum;
    },
    allowAgentNet (): boolean {
      if (this.isSuperAdmin || this.isFinancialAdmin) return true;

      return this.showBrokerNet;
    }
  },

  methods: {
    getSectionData (sectionName: UserWidgetsEnum): IUserLayoutWidget {
      return this.dashboardWidgets.find((section) => section.name === sectionName) || { cols: 12 } as IUserLayoutWidget;
    },
    updateWidgets ({ name, value }: { name: UserWidgetsEnum, value: boolean }) {
      const payload = this.dashboardWidgets.map((section) => {
        if (section.name === name) {
          section.is_toggled = value;
        }

        return section;
      });

      this.saveOrder(payload);
    },
  }
});
