
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue'
import { getFormattedAddress } from '@/bundles/App/helpers/address/getFormattedAddress';
import { formatCurrency, formatDate, formatPercent } from '@/utils/filters';
import { getAccountRouteHandler } from '@/bundles/App/helpers';
import { IComparable, TransactionTypesEnum } from '@/bundles/Comparables/types';
import { getComparableLeaseTermLength } from '@/bundles/Comparables/helpers';

import AvailabilityChipsWrapper from '@/bundles/Availabilities/components/chips/AvailabilityChipsWrapper.vue';
import Label from '@/bundles/Common/components/Label.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';
import POLIcon from '@/bundles/Comparables/components/POLIcon.vue';
import ComparableSalesPriceLabel from '@/bundles/Comparables/components/labels/ComparableSalesPriceLabel.vue';

export default Vue.extend({
  name: 'ComparableMobileCard',

  functional: true,

  props: {
    item: {
      type: Object as PropType<IComparable>,
      required: true
    },
    activeAccountId: {
      type: Number,
      required: true
    }
  },

  render (h: CreateElement, { props, data }: RenderContext<{ item: IComparable; activeAccountId: number }>): VNode | VNode[] {
    const isSale = props.item.transaction_type === TransactionTypesEnum.sale;

    return h('v-card', {
      props: { outlined: true },
      class: `comparable-mobile-card ${data.class || ''} ${data.staticClass || ''}`.trim(),
      attrs: { ...data.attrs },
    }, [
      h('div', { class: 'd-flex gap-2' }, [
        h('v-img', {
          props: {
            src: props.item.photo_links?.medium_url || props.item?.photo?.url || (parent as any).$placeholderPropertyImg,
            aspectRatio: 16 / 9,
            contain: true,
            position: 'center top',
            minWidth: '160px',
            maxWidth: '320px'
          },
          class: 'comparable-mobile-card__img'
        }, [
          h('div', { class: 'transaction-type-label' }, isSale ? 'SOLD' : 'LEASED'),
        ]),
        h('div', { class: 'comparable-mobile-card__address-info px-3 pt-3' }, [
          h('div', { class: 'record-id' }, `Comparable ID# ${props.item._key}`),
          h('router-link', {
            props: {
              to: getAccountRouteHandler({
                name: 'comparable',
                params: { id: props.item._key },
                activeAccountId: props.activeAccountId,
              })
            },
            class: 'font-weight-bold app-link comparable-mobile-card__name'
          }, props.item.name),
          h('div', { class: 'white-space--pre-line comparable-mobile-card__address' }, [
            getFormattedAddress(props.item.address, { includeStreet2: true })
          ])
        ])
      ]),
      h('div', { class: 'pa-3 pt-2 d-flex flex-column gap-2' }, [
        h(AvailabilityChipsWrapper, {
          props: {
            type: props.item.property_type,
            mixedUse: props.item.mixed_use,
            buildingClass: props.item.property.building_class,
            inHouse: props.item.in_house,
            isPortfolio: props.item.is_portfolio,
            isOneLine: true,
            allowWrap: true
          }
        }),
        h('div', { class: 'd-flex gap-2 flex-column' }, [
          h('div', { class: 'd-flex gap-3' }, [
            h('div', { class: 'comparable-mobile-card__info' }, [
              h('div', { class: 'comparable-mobile-card__info-label comparable-mobile-card__comp-size-label' }, 'Comp Size:'),
              h('div', { class: 'comparable-mobile-card__comp-size' }, [
                h(BuildingSizeLabel, { props: { value: props.item.transaction.total_square_feet } })
              ])
            ]),
            h('div', { class: 'comparable-mobile-card__info' }, [
              h('div', {
                  class: `comparable-mobile-card__info-label ${isSale ? 'comparable-mobile-card__sale-price-label' : 'comparable-mobile-card__lease-rate-starting-label'}`
                },
                isSale ? 'Sale Price (PSF):' : 'Lease Rate Starting (Ann Esc):'
              ),
              h('div', {
                class: isSale ? 'comparable-mobile-card__sale-price' : 'comparable-mobile-card__lease-rate-starting'
              }, [
                isSale
                  ? h(ComparableSalesPriceLabel, {
                      props: {
                        propertyType: props.item.property?.type || '',
                        totalAcres: props.item.transaction.total_acres,
                        totalPrice: props.item.transaction.total_price,
                        totalPricePer: props.item.transaction.total_price_per,
                        hideLabel: true,
                      }
                    })
                  : h(Label, {
                      props: {
                        value: props.item.transaction.starting_rent_sf ? `${formatCurrency(props.item.transaction.starting_rent_sf)} / SF (${formatPercent(props.item.transaction.escalation)})` : null
                      }
                    })
              ])
            ])
          ]),
          h('div', { class: 'd-flex gap-3' }, [
            h('div', { class: 'comparable-mobile-card__info' }, [
              h('div', { class: 'comparable-mobile-card__info-label comparable-mobile-card__building-size-label' }, 'Building Size:'),
              h('div', { class: 'comparable-mobile-card__building-size' }, [
                h(BuildingSizeLabel, { props: { value: props.item.property.building_size } })
              ])
            ]),
            h('div', { class: 'comparable-mobile-card__info' }, [
              h('div', {
                class: `comparable-mobile-card__info-label ${isSale ? 'comparable-mobile-card__cap-rate-label' : 'comparable-mobile-card__lease-rate-effective-label'}`
              }, isSale ? 'Cap Rate:' : 'Lease Rate Effective:'),
              h('div', {
                class: isSale ? 'comparable-mobile-card__cap-rate' : 'comparable-mobile-card__lease-rate-effective'
              }, [
                isSale
                    ? h(Label, { props: { value: props.item.transaction.cap_rate ? formatPercent(props.item.transaction.cap_rate) : null } })
                    : h(Label, { props: { value: props.item.transaction.effective_rent_sf ? `${formatCurrency(props.item.transaction.effective_rent_sf)} / SF` : null } })
              ])
            ])
          ]),
          h('div', { class: 'd-flex gap-3' }, [
            h('div', { class: 'comparable-mobile-card__info' }, [
              h('div', { class: 'comparable-mobile-card__info-label comparable-mobile-card__land-size-label' }, 'Land Size:'),
              h('div', { class: 'comparable-mobile-card__land-size' }, [
                h(LandSizeLabel, { props: { value: props.item.transaction.total_acres } }),
                props.item.transaction.land_pol ? h(POLIcon, { class: 'ml-2' }) : null
              ])
            ]),
            isSale ? null : h('div', { class: 'comparable-mobile-card__info' }, [
              h('div', { class: 'comparable-mobile-card__info-label comparable-mobile-card__lease-comm-term-label' }, 'Lease Comm (term):'),
              h('div', { class: 'comparable-mobile-card__lease-comm-term' }, [
                h(Label, {
                  props: {
                    value: props.item.transaction.lease_commencement ? `${formatDate(props.item.transaction.lease_commencement)} (${getComparableLeaseTermLength(props.item)})` : null
                  }
                })
              ])
            ])
          ])
        ])
      ])
    ])
  }
})
