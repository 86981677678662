import { ICriticalDateNotification } from '@/bundles/CriticalDates/interfaces';

export * from './mapCriticalDateTypeToTitle';

export const criticalDatesHeadersData = [
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Occupier', value: 'lease_date.occupier.name', sortable: true, icon: 'string' },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Title', value: 'type', sortable: true, icon: 'string' },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Type', value: 'lease_date.type_name', sortable: true, icon: 'string' },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Date', value: 'date', sortable: true, icon: 'date' },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Notifications', value: 'reminder.date', sortable: false, icon: 'date' },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Description', value: 'lease_date.description', sortable: false },
    ],
  },
];

export const getDefaultCriticalDateNotification = (): ICriticalDateNotification => ({
  date: null,
  reminders: [],
})
