
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { IOpenBalanceListItem } from '@/bundles/Deal/types';
import { formatCurrency, formatDate } from '@/utils/filters';
import { getAccountRouteHandler } from '@/bundles/App/helpers';

export default Vue.extend({
  name: 'OpenBalancesMobileCard',

  functional: true,

  props: {
    item: {
      type: Object as PropType<IOpenBalanceListItem>,
      required: true
    },
    activeAccountId: {
      type: Number,
      required: true
    }
  },

  render (h: CreateElement, { props, data }: RenderContext<{ item: IOpenBalanceListItem; activeAccountId: number; }>): VNode | VNode[] {
    return h('v-card', {
      props: { outlined: true },
      class: `open-balances-mobile-card  ${data.class || ''} ${data.staticClass || ''}`.trim(),
      attrs: { ...data.attrs },
    }, [
      h('div', { class: 'd-flex align-center pa-3', }, [
        h('router-link', {
          class: 'open-balances-mobile-card__title app-link',
          props: {
            to: getAccountRouteHandler({
              name: 'deal',
              params: { id: props.item._key },
              activeAccountId: props.activeAccountId,
            })
          }
        }, props.item.name),
        h('v-spacer'),
        h('span', { class: 'open-balances-mobile-card__transaction-date text-right' }, [
          'Transaction Date: ',
          h('span', formatDate(props.item.transactionDate))
        ])
      ]),
      h('v-divider'),
      h('div', { class: 'pa-3 d-flex flex-column gap-3' }, [
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'open-balances-mobile-card__info' }, [
            h('div', { class: 'open-balances-mobile-card__info-label' }, 'Total Commission:'),
            h('div', { class: 'open-balances-mobile-card__expected' }, formatCurrency(props.item.expected))
          ]),
          h('div', { class: 'open-balances-mobile-card__info' }, [
            h('div', { class: 'open-balances-mobile-card__info-label' }, 'Total In-House Commission:'),
            h('div', { class: 'open-balances-mobile-card__in-house-expected' }, formatCurrency(props.item.inHouseExpected))
          ])
        ]),
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'open-balances-mobile-card__info' }, [
            h('div', { class: 'open-balances-mobile-card__info-label' }, 'Total Paid:'),
            h('div', { class: 'open-balances-mobile-card__payments' }, formatCurrency(props.item.payments))
          ]),
          h('div', { class: 'open-balances-mobile-card__info' }, [
            h('div', { class: 'open-balances-mobile-card__info-label' }, 'Broker Gross:'),
            h('div', { class: 'open-balances-mobile-card__broker-gross' }, formatCurrency(props.item.brokerGross))
          ])
        ]),
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'open-balances-mobile-card__info' }, [
            h('div', { class: 'open-balances-mobile-card__info-label' }, 'Total Outstanding:'),
            h('div', { class: 'open-balances-mobile-card__remaining' }, formatCurrency(props.item.remaining))
          ]),
          h('div', { class: 'open-balances-mobile-card__info' }, [
            h('div', { class: 'open-balances-mobile-card__info-label' }, 'Broker Gross Outstanding:'),
            h('div', { class: 'open-balances-mobile-card__broker-gross-remaining' }, formatCurrency(props.item.brokerGrossRemaining))
          ])
        ])
      ])
    ]);
  }
});
