































































































































































import mixins from 'vue-typed-mixins';
import { PaginationMixin } from '@/bundles/BaseTable/mixins/PaginationMixin';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { criticalDateService } from '@/bundles/CriticalDates/factory/criticalDateServiceFactory';
import CriticalDateListResponse from '@/bundles/CriticalDates/models/CriticalDateListResponse';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { criticalDatesHeadersData, mapCriticalDateTypeToTitle } from '@/bundles/CriticalDates/helpers';
import { IHeader } from '@/bundles/BaseTable/interfaces';
import { Nullable } from '@/utils/types';
import Member from '@/bundles/Members/models/Member';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { concat } from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import BaseTableHeaderMulti from '@/bundles/BaseTable/components/BaseTableHeaderMulti.vue';
import OpenInvoicesDateFilter from '@/bundles/Dashboard/components/filters/OpenInvoicesDateFilter.vue';
import CriticalDateMobileCard from '@/bundles/CriticalDates/components/CriticalDateMobileCard.vue';

type MemberWithFullName = Member & { full_name: string };

interface ICriticalDateWidgetInstance {
  loading: boolean;
  items: CriticalDateListResponse[];
  totalRows: number;
  showOnlyFutureDates: boolean;
  tableOptionsType: 'criticalDatesDashboardWidget';
  date_start: Nullable<string>;
  date_end: Nullable<string>;
  memberId: Nullable<string>;
  members: MemberWithFullName[];
  filtersMenu: boolean;
}

export default mixins(PaginationMixin, AccountLayoutMixin).extend({
  name: 'CriticalDatesDashboardWidget',

  components: {
    CriticalDateMobileCard,
    OpenInvoicesDateFilter,
    DashboardCard,
    BaseTableHeaderMulti
  },

  props: {
    expanded: Boolean,
  },

  data: (): ICriticalDateWidgetInstance => ({
    loading: false,
    items: [],
    totalRows: 0,
    showOnlyFutureDates: false,
    tableOptionsType: 'criticalDatesDashboardWidget',
    date_start: null,
    date_end: null,
    memberId: null,
    members: [],
    filtersMenu: false,
  }),

  computed: {
    widgetType (): UserWidgetsEnum.critical_dates {
      return UserWidgetsEnum.critical_dates;
    },
    headers (): IHeader[] {
      return criticalDatesHeadersData
    },
    isSortDescending () {
      return !!this.tableOptions.sortDesc?.[0];
    }
  },

  created () {
    this.tableOptions.itemsPerPage = 10;

    // @ts-ignore
    if (this.isAdmin) {
      this.getMembers();
    }
  },

  watch: {
    tableOptions: {
      handler: function () {
        this.fetchWrapper();
      },
      deep: true,
      immediate: true,
    },
    showOnlyFutureDates: function () {
      if (this.tableOptions.page === 1) {
        this.fetchWrapper();
        return false;
      }
      this.tableOptions.page = 1;
    }
  },

  methods: {
    mapCriticalDateTypeToTitle,
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    fetchWrapper: async function () {
      this.loading = true;
      try {
        const params = {
          take: this.tableOptions.itemsPerPage,
          skip: this.pageOffset,
          sort: this.tableOptions.sortBy?.[0],
          descending: this.tableOptions.sortDesc?.[0],
          show_only_future_dates: this.showOnlyFutureDates,
        };

        if (this.date_start) {
          Object.assign(params, { date_start: this.date_start });
        }
        if (this.date_end) {
          Object.assign(params, { date_stop: this.date_end });
        }
        // @ts-ignore
        if (this.isAdmin && this.memberId) {
          Object.assign(params, { member: this.memberId });
        }

        const { data, total } = await criticalDateService.list(params);
        this.items = data;
        this.totalRows = total;
        const wrapper = document.querySelector('.critical-date-container');
        if (wrapper) {
          wrapper.scrollTop = 0;
        }
      } catch (error: any) {
        this.addNotification({ ...error });
      } finally {
        this.loading = false;
      }
    },
    isActiveSort: function (item: IHeader): boolean {
      const sortBy = this.tableOptions?.sortBy || [];
      const valueText = item.value;

      return sortBy[0] === valueText;
    },
    sortChangeHandler: function (payload: { sortBy: string[]; sortDesc: boolean[] }) {
      this.setFilterSort('criticalDatesDashboardWidget', payload);
    },
    async getMembers () {
      const params = [
        { name: 'take', value: 0 },
        { name: 'status', value: 'active' },
      ]

      const { data } = await memberService.list(params);

      this.members = data.map((item) => {
        return {
          ...item,
          full_name: `${item.profile.first_name} ${item.profile.last_name}`
        }
      });
    },
    changeUser (value: string) {
      this.memberId = value;
      this.fetchWrapper();
    },
    getNotificationItems (criticalDate) {
      const inside_reminders = criticalDate.lease_date.inside_notification.reminders || [];
      const outside_reminders = criticalDate.lease_date.outside_notification.reminders || [];

      const reminders = concat(inside_reminders, outside_reminders);

      return reminders.filter(reminder => !!reminder.prior_to.toCompare).map(reminder => {
        const date = DateTime.fromISO(criticalDate.date).minus(reminder.prior_to.toCompare).valueOf();

        return {
          date,
          isDateInFuture: date > Date.now(),
          key: uuid()
        }
      });
    }
  },
});
