
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue'
import { IBrokerGrossListItem } from '@/bundles/Deal/types';
import { formatCurrency, formatNumber } from '@/utils/filters';
import { getBrokerNet } from '@/bundles/Dashboard/helpers';

import Label from '@/bundles/Common/components/Label.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';

interface IProps {
  item: IBrokerGrossListItem;
  showBrokerGross: boolean;
  reportType: string;
  isBrokerNetAllowed: boolean;
}

export default Vue.extend({
  name: 'AgentRankingMobileCard',

  functional: true,

  props: {
    item: {
      type: Object as PropType<IBrokerGrossListItem>,
      required: true
    },
    showBrokerGross: Boolean,
    isBrokerNetAllowed: Boolean,
    reportType: {
      type: String,
      default: 'paid'
    }
  },

  render (h: CreateElement, { props, data }: RenderContext<IProps>): VNode | VNode[] {
    return h('v-card', {
      props: { outlined: true },
      class: `agent-ranking-mobile-card  ${data.class || ''} ${data.staticClass || ''}`.trim(),
      attrs: { ...data.attrs },
    }, [
      h('div', { class: 'd-flex align-center' }, [
        h('div', { class: 'agent-ranking-mobile-card__rank-wrapper' }, [
          h('span', '#'),
          h('span', { class: 'agent-ranking-mobile-card__rank' }, props.item.ranking.toString()),
        ]),
        h('span', { class: 'agent-ranking-mobile-card__name pl-2 py-1 font-weight-bold' }, props.item.name),
      ]),
      h('v-divider'),
      h('div', { class: 'd-flex gap-2 flex-column pa-3' }, [
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'agent-ranking-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__deals-label' }, '# of Deals:'),
            h('div', { class: 'agent-ranking-mobile-card__deals' }, [
              h(Label, { props: { value: formatNumber(props.item.dealIds.length) } })
            ])
          ]),
          h('div', { class: 'agent-ranking-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__consideration-label' }, 'Consideration:'),
            h('div', { class: 'agent-ranking-mobile-card__consideration' }, [
              h(Label, { props: { value: formatCurrency(props.item.consideration) } })
            ])
          ])
        ]),
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'agent-ranking-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__building-size-label' }, 'Building Size:'),
            h('div', { class: 'agent-ranking-mobile-card__building-size' }, [
              h(BuildingSizeLabel, { props: { value: props.item.buildingSize } })
            ])
          ]),
          h('div', { class: 'agent-ranking-mobile-card__info' }, [
            h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__land-size-label' }, 'Land Size:'),
            h('div', { class: 'agent-ranking-mobile-card__land-size' }, [
              h(LandSizeLabel, { props: { value: props.item.landSize } })
            ])
          ])
        ]),
      ]),
      props.showBrokerGross ? h('div', { class: 'd-flex gap-3 pa-3 agent-ranking-mobile-card__commission-info' }, [
        h('div', { class: 'agent-ranking-mobile-card__info' }, [
          h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__agent-gross-label' }, 'Agent Gross:'),
          h('div', { class: 'agent-ranking-mobile-card__agent-gross' }, [
            h(Label, { props: { value: formatCurrency(props.item.brokerGross) } })
          ])
        ]),
        h('div', { class: 'agent-ranking-mobile-card__info' }, [
          h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__agent-net-label' }, 'Agent Net:'),
          h('div', { class: 'agent-ranking-mobile-card_agent' }, [
            h(Label, { props: { value: props.reportType === 'paid' ? formatCurrency(props.item.brokerNet) : null } })
          ])
        ]),
        h('div', { class: 'agent-ranking-mobile-card__info' }, [
          h('div', { class: 'agent-ranking-mobile-card__info-label agent-ranking-mobile-card__vacancy-status-label' }, 'Broker Net:'),
          h('div', { class: 'agent-ranking-mobile-card__vacancy-status' }, [
            h(Label, {
              props: {
                value: getBrokerNet({
                  brokerGross: props.item.brokerGross,
                  brokerNet: props.item.brokerNet,
                  reportType: props.reportType,
                  isBrokerNetAllowed: props.isBrokerNetAllowed
                })
              }
            })
          ])
        ])
      ]) : null,
    ])
  }
})
